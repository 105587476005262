import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../style/admin/AdminScreenshot.scss';

export default function AdminScreenshot() {
  const [screenshots, setScreenshots] = useState([]);
  const [editingScreenshot, setEditingScreenshot] = useState(null);
  const [selectedTag, setSelectedTag] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    tag: '',
    image: null,  // Initialisez l'image à null
    createdBy: ''
  });

  const tagsList = [
    { name: 'Dofus' },
    { name: 'DiabloIV' },
    { name: 'Satisfactory' },
    { name: 'ManofMedan' },
    { name: 'WorldofWarcraft' },
    { name: 'GTAV' },
    { name: 'Bramble' },
  ];

  useEffect(() => {
    async function fetchScreenshots() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/screenshots`);
        setScreenshots(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des screenshots:", error);
      }
    }
    fetchScreenshots();
  }, []);

  const handleEditClick = (screenshot) => {
    setEditingScreenshot(screenshot._id);
    setFormData({
      title: screenshot.title || '',
      tag: screenshot.tag || '',
      image: screenshot.image || null, // Conservez l'image actuelle
      createdBy: screenshot.createdBy || ''
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        image: file,  // Mettez à jour l'image si un fichier est sélectionné
      });
    }
  };

  const handleSaveClick = async () => {
    const data = new FormData();
    data.append('title', formData.title);
    data.append('tag', formData.tag);
    data.append('createdBy', formData.createdBy);

    if (formData.image instanceof File) {
      data.append('image', formData.image); // Ajoutez l'image si un fichier est sélectionné
    } else {
      data.append('image', ''); // Pas d'image si aucun fichier
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}api/screenshots/${editingScreenshot}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setEditingScreenshot(null);
      setFormData({
        title: '',
        tag: '',
        image: null, // Réinitialiser l'image après l'enregistrement
        createdBy: ''
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour du screenshot:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}api/screenshots/${id}`);
      setScreenshots((prevScreenshots) => prevScreenshots.filter((screenshot) => screenshot._id !== id));
    } catch (error) {
      console.error("Erreur lors de la suppression du screenshot:", error);
    }
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const uniqueTags = Array.from(new Set(screenshots.flatMap(screenshot => screenshot.tag)));
  const filteredScreenshots = selectedTag ? screenshots.filter((screenshot) => screenshot.tag.includes(selectedTag)) : screenshots;

  return (
    <div className='admin-screenshot'>
      <h2>Gestion des Screenshots</h2>

      <div className="filter">
        <label htmlFor="tagFilter">Filtrer par tag:</label>
        <select value={selectedTag} onChange={handleTagChange}>
          <option value="">Tous les tags</option>
          {uniqueTags.map(tag => (
            <option key={tag} value={tag}>{tag}</option>
          ))}
        </select>
      </div>

      <ul>
        {filteredScreenshots.map((screenshot) => (
          <li key={screenshot._id}>
            {editingScreenshot === screenshot._id ? (
              <div>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleFormChange}
                  placeholder="Titre"
                />
                <select
                  name="tag"
                  value={formData.tag}
                  onChange={handleFormChange}
                >
                  <option value="">Aucun</option>
                  {tagsList.map((tag) => (
                    <option key={tag.name} value={tag.name}>{tag.name}</option>
                  ))}
                </select>
                <input
                  type="file"
                  onChange={handleImageChange}
                />
                
                {/* Affichage de l'image actuelle */}
               
                <img src={`${screenshot.image}`} alt={screenshot.title} />
                
                
                <input
                  type="text"
                  name="createdBy"
                  value={formData.createdBy}
                  onChange={handleFormChange}
                  placeholder="Créateur"
                />
                <button onClick={handleSaveClick}>Enregistrer</button>
                <button onClick={() => setEditingScreenshot(null)}>Annuler</button>
              </div>
            ) : (
              <div className='apercu-screen'>
                <h3>{screenshot.title}</h3>
                <p>{screenshot.tag}</p>
                <p>Créateur: {screenshot.createdBy}</p>
                <img src={`${screenshot.image}`} alt={screenshot.title} />
                <button onClick={() => handleEditClick(screenshot)}>Éditer</button>
                <button onClick={() => handleDeleteClick(screenshot._id)}>Supprimer</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
