import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../style/admin/dofus/Archimonstre.scss'; // Assurez-vous d'importer votre fichier CSS ici

export default function Archimonstre() {
  const [searchTerm, setSearchTerm] = useState('');
  const [zoneTerm, setZoneTerm] = useState('');
  const [typeTerm, setTypeTerm] = useState('');
  const [monsters, setMonsters] = useState([]);

  useEffect(() => {
    const fetchMonsters = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/monsters`);
      setMonsters(response.data);
    };

    fetchMonsters();
  }, []);

  const handleCheckboxChange = async (id) => {
    const updatedMonsters = monsters.map(monster => {
      if (monster._id === id) {
        const newCaptureComplete = !monster.captureComplete; // Inverser la case à cocher
        return { ...monster, captureComplete: newCaptureComplete };
      }
      return monster;
    });

    setMonsters(updatedMonsters);

    // Mettre à jour dans la base de données
    await axios.patch(`${process.env.REACT_APP_API_URL}api/monsters/${id}`, { captureComplete: updatedMonsters.find(m => m._id === id).captureComplete });
  };

  const handleCounterChange = async (id, value) => {
    const updatedMonsters = monsters.map(monster => {
      if (monster._id === id) {
        const newCaptureCount = Math.max(0, Math.min(4, value)); // Limite entre 0 et 4
        const newCaptureComplete = newCaptureCount === 4;
        return { ...monster, compteur: newCaptureCount, captureComplete: newCaptureComplete };
      }
      return monster;
    });

    setMonsters(updatedMonsters);

    // Mettre à jour dans la base de données
    await axios.patch(`${process.env.REACT_APP_API_URL}api/monsters/${id}`, { compteur: updatedMonsters.find(m => m._id === id).compteur, captureComplete: updatedMonsters.find(m => m._id === id).captureComplete });
  };
  const getCapturedCount = () => {
    return monsters.filter(archi => archi.captureComplete).length;
  };

  const getProgressPercentage = () => {
    const totalMonsters = 636; // Total des monstres
    const capturedCount = getCapturedCount();
    return Math.min((capturedCount / totalMonsters) * 100, 100); // Ne pas dépasser 100%
  };

  const filteredMonsters = () => {
    return monsters.filter(archi => 
      archi.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      archi.zones.some(zone => zone.toLowerCase().includes(zoneTerm.toLowerCase())) &&
      archi.type.toLowerCase().includes(typeTerm.toLowerCase())
    );
  };

  return (
    <div className="table-container-archimonstre">
      <h1>Archimonstres de Dofus</h1>
      <input
        type="text"
        placeholder="Rechercher un archimonstre par nom..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <input
        type="text"
        placeholder="Rechercher par zone..."
        value={zoneTerm}
        onChange={(e) => setZoneTerm(e.target.value)}
      />
      <input
        type="text"
        placeholder="Rechercher par type..."
        value={typeTerm}
        onChange={(e) => setTypeTerm(e.target.value)}
      />
       <div className="progress-bar">
        <div
          className="progress-fill"
          style={{ width: `${getProgressPercentage()}%` }}
        ></div>
      </div>
      <p>LES 4 OCRES / {getProgressPercentage().toFixed(0)}%</p>
      <table>
        <thead>
          <tr className='all-colonnes'>
            <th>Nom</th>
            <th>Zone d'apparition</th>
            <th>Type</th>
            <th>Compteur</th>
            <th>coché si 4 captures</th>
          </tr>
        </thead>
        <tbody>
          {filteredMonsters().map((archi) => (
            <tr key={archi._id}>
              <td>{archi.name}</td>
              <td>{archi.zones.join(', ')}</td>
              <td>{archi.type}</td>
              <td>
                <input
                  type="number"
                  value={archi.compteur}
                  onChange={(e) => handleCounterChange(archi._id, Number(e.target.value))}
                  min="0"
                  max="4"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={archi.captureComplete}
                  onChange={() => handleCheckboxChange(archi._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
