import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import '../style/ScreenshotCarousel.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const SamplePrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={onClick}>
    &lt; {/* Flèche gauche */}
  </div>
);

const SampleNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={onClick}>
    &gt; {/* Flèche droite */}
  </div>
);

const ScreenshotCarousel = ({ screenshots, initialIndex, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const sliderRef = useRef(null);

  useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    beforeChange: (current, next) => setActiveIndex(next),
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  const handlePreviewClick = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className='screenshot-carousel' style={{ position: 'relative' }}>
    
      <Slider ref={sliderRef} {...settings}>
        {screenshots.map((screenshot, index) => (
          <div key={screenshot._id} className='screenshot-item'>
<img 
  src={screenshot.image} // Utilise directement l'URL Cloudinary
  alt={screenshot.title} 
  className='screenshot-image' 
/>
            <div className='screenshot-info'>
              <h3>{screenshot.title}</h3>
              <p>Date: {new Date(screenshot.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
        ))}
      </Slider>

      <div className='screenshot-preview-gallery'>
        {screenshots.map((screenshot, index) => (
          <img 
  src={screenshot.image} // Utilise directement l'URL Cloudinary
  alt={screenshot.title} 
  className={`screenshot-preview ${index === activeIndex ? 'active' : ''}`} 
  onClick={() => handlePreviewClick(index)} 
  key={screenshot._id} 
/>
        ))}
      </div>
    </div>
  );
};

export default ScreenshotCarousel;
