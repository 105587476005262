import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import '../style/ArticleDetail.scss';
import Close from '../images/Admin/CLOSE.svg';

export default function ArticleDetail() {
  const location = useLocation();
  const { article } = location.state;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Retourner à la page précédente
  };

  // Formater la date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="article-detail">
      <h1>{article.title}</h1>
      {article.coverImage && <img src={`${article.coverImage}`} alt={article.title} />}
      
      {/* Séparer card-desc et card-infos pour éviter le conflit */}
      <div className="card-desc" dangerouslySetInnerHTML={{ __html: marked(article.content || '') }} />
      
      <div className="card-infos">
        <p>Créé par : {article.createdBy}</p>
        <p>Le : {formatDate(article.createdAt)}</p>
      </div>

      <img className='close'
        src={Close}
        alt="Retour"
        onClick={handleBack}
        style={{ cursor: 'pointer', width: '50px', height: '50px', margin: '0' }}
      />
    </div>
  );
}
