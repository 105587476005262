// LiveSection.js
import React from 'react';

function LiveSection({ liveId }) {
  // Détecte si l'environnement est local ou production
  const isLocal = window.location.hostname === 'localhost';
  
  return (
    <div className="live-section">
      <iframe
        src={`https://player.twitch.tv/?channel=${liveId}&parent=${isLocal ? 'localhost' : 'ludic-adventure.fr'}`}
        height="700"
        width="65%"
        className='iframe1'
        allowFullScreen
      ></iframe>
      <iframe
        src={`https://www.twitch.tv/embed/${liveId}/chat?parent=${isLocal ? 'localhost' : 'ludic-adventure.fr'}&darkpopout=true`}
        height="700"
        width="30%"
        className='iframe2'
      ></iframe>
    </div>
  );
}

export default LiveSection;
