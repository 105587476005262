import React from 'react'
import '../style/error404.scss';

import Tete from '../images/error404/Group737.png'
import Button from '../images/error404/Group771.png'
import Texte from '../images/error404/Ohnon.png'
import Logo from '../images/error404/Logo.svg'
import { NavLink } from 'react-router-dom'


export default function Error404() {
  return (
    <div className='all-error'>
        <div className='logotop'>
            <img src={Logo} alt="error-logo"/>
        </div>
        <div className='troisbloc'>
            <div>
                <img src={Tete} alt="error-tete"/>
            </div>
            <div>
                <img src={Texte} alt="error-text"/>
            </div>
            <div>
            <NavLink to="/">
                <img src={Button} alt="error-button" />
            </NavLink>
            </div>
        </div>
    </div>
  )
}
