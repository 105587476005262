// ScreenshotForm.js
import React from 'react';

const ScreenshotForm = ({ screenshot, handleScreenshotChange, handleScreenshotSubmit, tagsList }) => {
  return (
    <div>
      <h2>Ajouter un Screenshot</h2>
      <form onSubmit={handleScreenshotSubmit}>
        <input name="title" value={screenshot.title} onChange={handleScreenshotChange} placeholder="Titre" required />
        <input type="file" name="image" onChange={handleScreenshotChange} required />
        <input name="creator" value={screenshot.creator} onChange={handleScreenshotChange} placeholder="Nom du créateur" required />
        <select name="tag" value={screenshot.tag} onChange={handleScreenshotChange} required>
          <option value="">Choisir un tag</option>
          {tagsList.map((tag, index) => (
            <option key={index} value={tag.name}>{tag.name}</option>
          ))}
        </select>
        <button type="submit">Créer un Screenshot</button>
      </form>
    </div>
  );
};

export default ScreenshotForm;
