import React, { useState } from 'react';
import axios from 'axios';
import ArticleForm from './ArticleForm';  // Importer le composant ArticleForm
import ScreenshotForm from './ScreenshotForm';  // Importer le composant ScreenshotForm
import '../../style/admin/AdminPanel.scss';

export default function AdminPanel() {
  const [article, setArticle] = useState({ title: '', content: '', image: null, creator: '', tag: '' });
  const [screenshot, setScreenshot] = useState({ title: '', image: null, creator: '', tag: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // Ajouter l'état pour le message de succès

  const tagsList = [
    { name: 'Dofus' },
    { name: 'EpopeeFraternelle'},
    { name: 'Aventurea2'},
    { name: 'DiabloIV' },
    { name: 'Satisfactory' },
    { name: 'ManofMedan' },
    { name: 'WorldofWarcraft' },
    { name: 'GTAV' },
    { name: 'Bramble' },
  ];

  const handleArticleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      // Assurer que l'image est bien récupérée
      setArticle({ ...article, [name]: files[0] });
    } else {
      setArticle({ ...article, [name]: value });
    }
  };
  

  const handleScreenshotChange = (e) => {
    const { name, value, files } = e.target;
    setScreenshot((prevScreenshot) => ({
      ...prevScreenshot,
      [name]: name === 'image' ? files[0] : value,
    }));
  };

  const handleArticleSubmit = async (e) => {
    e.preventDefault();

    // Créer un nouvel objet FormData pour envoyer les données et l'image
    const formData = new FormData();
    formData.append('title', article.title);
    formData.append('creator', article.creator);
    formData.append('tag', article.tag);
    formData.append('content', article.content);
    formData.append('image', article.image); // Ajouter l'image sélectionnée

    try {
      // Utiliser axios pour envoyer les données
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/articles`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Assurer le bon type de contenu pour le fichier
        },
      });

      // Vérifier la réponse
      if (response.data.article) {
        console.log('Article créé avec succès', response.data.article);
        setSuccessMessage('Article créé avec succès'); // Afficher le message de succès
        // Réinitialiser l'état du formulaire après succès
        setArticle({ title: '', content: '', image: null, creator: '', tag: '' });
      } else {
        console.error('Erreur de création d\'article:', response.data.message);
        setError('Erreur lors de la création de l\'article');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire:', error);
      setError('Erreur lors de l\'envoi de l\'article');
    }
  };

  const handleScreenshotSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', screenshot.title);
    formData.append('image', screenshot.image);
    formData.append('creator', screenshot.creator);
    formData.append('tag', screenshot.tag);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/screenshots`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Screenshot créé:', response.data);
      setSuccessMessage('Screenshot créé avec succès'); // Afficher le message de succès
      setScreenshot({ title: '', image: null, creator: '', tag: '' });
    } catch (error) {
      console.error('Erreur lors de la création de la capture d\'écran:', error);
      setError("Erreur lors de la création de la capture d'écran");
    }
  };

  return (
    <div className='home-container'>
      <h1>Admin Panel</h1>
      <ArticleForm
        article={article}
        handleArticleChange={handleArticleChange}
        handleArticleSubmit={handleArticleSubmit}
        tagsList={tagsList}
      />
            {successMessage && <p className="success">{successMessage}</p>} {/* Afficher le message de succès */}
            {error && <p className="error">{error}</p>}
      <ScreenshotForm
        screenshot={screenshot}
        handleScreenshotChange={handleScreenshotChange}
        handleScreenshotSubmit={handleScreenshotSubmit}
        tagsList={tagsList}
      />

    </div>
  );
}
