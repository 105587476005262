import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../style/admin/AdminPlanningVideo.scss';

export default function AdminPlaningVideo() {
  const [formData, setFormData] = useState({
    id: '',
    uploader: '',
    miniature: '',
    video: '',
    creationDate: '',
    playlist: '',
    isOnline: false,
  });

  const [videos, setVideos] = useState([]);

  // Fonction pour formater la date au format jj/mm/aaaa
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Récupérer les vidéos depuis l'API
  const fetchVideos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/add-videos`);
      setVideos(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des vidéos:', error);
    }
  };

  useEffect(() => {
    fetchVideos(); // Call fetchVideos when the component loads
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'creationDate') {
      const formattedDate = value ? value : '';
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (formData.id) {
        response = await axios.put(`${process.env.REACT_APP_API_URL}api/add-videos/${formData.id}`, formData);
        setVideos((prevVideos) => prevVideos.map((video) => (video._id === formData.id ? response.data : video)));
      } else {
        response = await axios.post(`${process.env.REACT_APP_API_URL}api/add-videos`, formData);
        setVideos((prevVideos) => [...prevVideos, response.data]);
      }

      fetchVideos(); // Call fetchVideos to refresh the video list
      setFormData({
        id: '',
        uploader: '',
        miniature: '',
        video: '',
        creationDate: '',
        playlist: '',
        isOnline: false,
      });
    } catch (error) {
      console.error('Erreur lors de l\'envoi des données:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}api/add-videos/${id}`);
      setVideos(videos.filter((video) => video._id !== id));
      console.log('Vidéo supprimée');
    } catch (error) {
      console.error('Erreur lors de la suppression de la vidéo:', error);
    }
  };

  const handleEdit = (video) => {
    setFormData({
      id: video._id,
      uploader: video.uploader,
      miniature: video.miniature,
      video: video.video,
      creationDate: video.creationDate,
      playlist: video.playlist,
      isOnline: video.isOnline,
    });
  };

  return (
    <div className="admin-planing-video">
      <h2>Planification des vidéos</h2>
      <form onSubmit={handleSubmit} className="video-form">
        <div className="form-group">
          <label>Uploader</label>
          <input
            type="text"
            name="uploader"
            value={formData.uploader}
            onChange={handleChange}
            placeholder="Nom de l'uploader"
          />
        </div>

        <div className="form-group">
          <label>Miniature </label>
          <input
            type="text"
            name="miniature"
            value={formData.miniature}
            onChange={handleChange}
            placeholder="Nom de la miniature"
          />
        </div>

        <div className="form-group">
          <label>Vidéo </label>
          <input
            type="text"
            name="video"
            value={formData.video}
            onChange={handleChange}
            placeholder="Nom de la vidéo"
          />
        </div>

        <div className="form-group">
          <label>Date de création</label>
          <input
            type="date"
            name="creationDate"
            value={formData.creationDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Playlist (Nom de la playlist)</label>
          <input
            type="text"
            name="playlist"
            value={formData.playlist}
            onChange={handleChange}
            placeholder="Nom de la playlist"
          />
        </div>

        <div className="form-group">
          <label>En ligne</label>
          <input
            type="checkbox"
            name="isOnline"
            checked={formData.isOnline}
            onChange={handleChange}
          />
        </div>

        <button type="submit">{formData.id ? 'Modifier' : 'Soumettre'}</button>
      </form>

      <h3>Liste des vidéos ajoutées</h3>
      <table>
        <thead>
          <tr>
            <th>Uploader</th>
            <th>Miniature</th>
            <th>Vidéo</th>
            <th>Date de création</th>
            <th>Playlist</th>
            <th>En ligne</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {videos.map((video) => (
            <tr key={video._id}>
              <td>{video.uploader}</td>
              <td>{video.miniature}</td>
              <td>{video.video}</td>
              <td>{formatDate(video.creationDate)}</td> {/* Affiche la date formatée */}
              <td>{video.playlist}</td>
              <td>{video.isOnline ? 'Oui' : 'Non'}</td>
              <td>
                <button onClick={() => handleEdit(video)}>Modifier</button>
                <button onClick={() => handleDelete(video._id)}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
