import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from '../composants/Nav';
import Actualite from '../pages/Actualite';
import Login from '../pages/Login';
import Jeux from '../pages/Jeux';
//Dofus
import DofusHome from '../pages/Dofus/DofusHome';
   // Epopée fraternelle
   import LepopeeFraternelleHome from '../pages/Dofus/Lepopee Fraternelle/LepopeeFraternelleHome';
   import LepopeeFraternelleVideo from '../pages/Dofus/Lepopee Fraternelle/LepopeeFraternelleVideo';
   import LepopeeFraternelleScreen from '../pages/Dofus/Lepopee Fraternelle/LepopeeFraternelleScreen';
   // aventures a deux
   import AventureA2Home from '../pages/Dofus/AventureA2/Aventurea2Home';
   import AventureA2Video from '../pages/Dofus/AventureA2/Aventurea2Video';
   import AventureA2Screen from '../pages/Dofus/AventureA2/Aventurea2Screen';
//Satisfactory
import SatisHome from '../pages/Satisfactory/SatisHome';
import SatisVideo from '../pages/Satisfactory/SatisVideo';
import SatisScreen from '../pages/Satisfactory/SatisScreen';
// bramble
import BrambleHome from '../pages/Bramble/BrambleHome';
import BrambleScreen from '../pages/Bramble/BrambleScreen';
import BrambleVideo from '../pages/Bramble/BrambleVideo';
// diablo
import Diablo4Home from '../pages/Diablo4/Diablo4Home';
import Diablo4Screen from '../pages/Diablo4/Diablo4Screen';
import Diablo4Video from '../pages/Diablo4/Diablo4Video';
// wow
import WowHome from '../pages/Wow/WowHome';
import WowScreen from '../pages/Wow/WowScreen';
import WowVideo from '../pages/Wow/WowVideo';
// gtav
import GtaVHome from '../pages/GtaV/GtaVHome';
import GtaVScreen from '../pages/GtaV/GtaVScreen';
import GtaVVideo from '../pages/GtaV/GtaVVideo';

// dark pictures
import DarkPictures from '../pages/DarkPicture/DarkPictureHome';
    // Monofmedan
    import ManOfMedanHome from '../pages/DarkPicture/ManOfMedan/ManOfMedanHome';
    import ManOfMedanScreen from '../pages/DarkPicture/ManOfMedan/ManOfMedanScreen';
    import ManOfMedanVideo from '../pages/DarkPicture/ManOfMedan/ManOfMedanVideo';
//Admin 
import AdminPanel from '../pages/Admin/AdminPanel';
import AdminScreenshot from '../pages/Admin/AdminScreenshot';
import AdminArticles from '../pages/Admin/AdminArticles';
import ArticleDetail from '../composants/ArticleDetail';
import AdminNav from '../pages/Admin/AdminNav';
import Archimonstre from '../pages/Admin/Dofus/Archimonstre';
import MountManagement from '../pages/Admin/Dofus/Elevage/MountManagement';
import StreamsNav from '../pages/Stream/SteamsNav';
import Apropos from '../pages/Apropos';
import Error404 from '../pages/Error404';
import AdminBanqueKamas from '../pages/Admin/AdminBanqueKamas';
import AdminLiens from '../pages/Admin/AdminLiens';
import AdminPlaningVideo from '../pages/Admin/AdminPlaningVideo';






const AppRoutes = () => {
  return (
    <Router>
        <Nav/>
      <Routes>
        <Route path="/" element={<Actualite />} />
        <Route path="/article/:id" element={<ArticleDetail/>} />
        <Route path="/jeux" element={<Jeux />} />
        <Route path="/a-propos" element={<Apropos />} />
        {/* Streams */}
        <Route path="/streams" element={<StreamsNav />} />

        {/* Admin */}
        <Route path="/admin-login" element={<Login />} />
        <Route path="/admin-nav" element={<AdminNav />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/admin-articles" element={<AdminArticles />} />
        <Route path="/admin-screenshots" element={<AdminScreenshot />} />
        <Route path="/admin-archimonstre" element={<Archimonstre />} />
        <Route path="/admin-banquekamas" element={<AdminBanqueKamas />} />
        <Route path="admin-liens" element={<AdminLiens/>} />
        <Route path="admin-planning" element={<AdminPlaningVideo/>} />
        {/*Elevage*/}
        <Route path='/admin-mount-management' element={<MountManagement/>} />
        {/* Dofus */}
        <Route path="/dofus" element={<DofusHome />} />
        {/* Dofus Epopee Fraternelle */}
        <Route path="/dofus/epopee-fraternelle" element={<LepopeeFraternelleHome />} />
        <Route path="/dofus/epopee-fraternelle/videos" element={<LepopeeFraternelleVideo  />} />
        <Route path="/dofus/epopee-fraternelle/screenshots" element={<LepopeeFraternelleScreen />} />
        {/*Dofus aventure a 2 */ }
        <Route path="/dofus/aventure-a-2" element={<AventureA2Home />} />
        <Route path="/dofus/aventure-a-2/videos" element={<AventureA2Video  />} />
        <Route path="/dofus/aventure-a-2/screenshots" element={<AventureA2Screen />} />
        {/* Satisfatory */}
        <Route path="/satisfactory" element={<SatisHome/>} />
        <Route path="/satisfactory/videos" element={<SatisVideo/>} />
        <Route path="/satisfactory/screenshots" element={<SatisScreen/>} />
         {/* Bramble */}
        <Route path="/bramble" element={<BrambleHome />} />
        <Route path="/bramble/screenshots" element={<BrambleScreen />} />
        <Route path="/bramble/videos" element={<BrambleVideo />} />
        
        {/* Diablo 4 */}
        <Route path="/diabloIV" element={<Diablo4Home />} />
        <Route path="/diabloIV/screenshots" element={<Diablo4Screen />} />
        <Route path="/diabloIV/videos" element={<Diablo4Video />} />
        
        {/* World of Warcraft */}
        <Route path="/worldofwarcraft" element={<WowHome />} />
        <Route path="/worldofwarcraft/screenshots" element={<WowScreen />} />
        <Route path="/worldofwarcraft/videos" element={<WowVideo />} />
        
        {/* GTA V */}
        <Route path="/gtav" element={<GtaVHome />} />
        <Route path="/gtav/screenshots" element={<GtaVScreen />} />
        <Route path="/gtav/videos" element={<GtaVVideo />} />
        
        {/*dark pictures*/}
        <Route path="/dark-pictures" element={<DarkPictures />} />

        {/* Man of Medan */}
        <Route path="/dark-pictures/manofmedan" element={<ManOfMedanHome />} />
        <Route path="/dark-pictures/manofmedan/screenshots" element={<ManOfMedanScreen />} />
        <Route path="/dark-pictures/manofmedan/videos" element={<ManOfMedanVideo />} />
      
       {/* Page non trouvée */}
       <Route path="*" element={<Error404 />} />
      
      </Routes>
    </Router>
  );
};

export default AppRoutes;
