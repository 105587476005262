import React from 'react';
import '../../style/streams/LiveLogo.scss';
import arfdevLogo from '../../images/Streams/Logo-FondBleu-vertical.png'; // Import de l'image
import bijy13Logo from '../../images/Streams/LOGO_HEX_Blanc_gris_new.png'; // Import de l'image

function LiveLogo({ liveId, isActive, isLive, onClick }) {
  // Dynamique du chemin de l'image en fonction du liveId
  const imagePath = liveId === 'arfdev' 
    ? arfdevLogo
    : liveId === 'bijy13'
    ? bijy13Logo
    : '/assets/images/default-logo.png'; // Image par défaut au cas où

  return (
    <div
      className={`live-logo ${isActive ? 'active' : ''} ${isLive ? 'live' : 'offline'}`}
      onClick={onClick}
    >
      {/* Affichage de l'image en fonction du liveId */}
      <img src={imagePath} alt={`${liveId} logo`} />
      
      {/* Affichage du statut du live */}
      <p className="live-status">{isLive ? 'Live On' : 'Live Off'}</p>
    </div>
  );
}

export default LiveLogo;
