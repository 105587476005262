import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer le thème de base de Quill


const ArticleForm = ({ article, handleArticleChange, handleArticleSubmit, tagsList }) => {
  const quillRef = useRef(null); // Ref pour ReactQuill

  return (
    <div className="article-form">
      <h2>Ajouter un Article</h2>
      <form onSubmit={handleArticleSubmit}>
        
        {/* Champ de titre */}
        <input 
          name="title" 
          value={article.title} 
          onChange={handleArticleChange} 
          placeholder="Titre" 
          required 
          className="input-field"
        />

        {/* Éditeur Quill */}
        <ReactQuill
          ref={quillRef}
          value={article.content}
          onChange={(value) => handleArticleChange({ target: { name: 'content', value } })}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['link', 'image', 'video'],
              ['clean'],
            ],
          }}
          
          placeholder="Écrire le contenu de l'article ici..."
          style={{ height: '250px', marginBottom: '20px' }}
        />

        {/* Champ d'importation d'image */}
        <div className="file-upload">
        <input 
            type="file" 
            name="image" 
            onChange={(e) => handleArticleChange(e)} 
            style={{ marginTop: '10px' }} 
            accept=".webp" // Limite aux fichiers .webp
          />

          <small>Choisissez une image .webp en 1080p pour l'article. 1mo max</small>
        </div>

        {/* Champ créateur */}
        <input 
          name="creator" 
          value={article.creator} 
          onChange={handleArticleChange} 
          placeholder="Nom du créateur" 
          required 
          className="input-field"
        />

        {/* Sélecteur de tag */}
        <select 
          name="tag" 
          value={article.tag} 
          onChange={handleArticleChange} 
          required
          className="input-field"
        >
          <option value="">Choisir un tag</option>
          {tagsList.map((tag, index) => (
            <option key={index} value={tag.name}>{tag.name}</option>
          ))}
        </select>

        {/* Bouton de soumission */}
        <button type="submit" className="submit-button">Créer un Article</button>
      </form>
    </div>
  );
};

export default ArticleForm;
