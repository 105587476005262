import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/Login.scss'; // Assure-toi que ce fichier est bien importé

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/auth/login`, { username, password });
      console.log('Connexion réussie', response.data);
      
      localStorage.setItem('token', response.data.token); // Stocker le token
      localStorage.setItem('role', response.data.role); // Stocker le rôle

      // Rediriger selon le rôle
      if (response.data.role === 'admin') {
        navigate('/admin-nav'); // Redirection vers la page admin
      } else {
        navigate('/'); // Redirection vers la page d'accueil pour les utilisateurs normaux
      }
    } catch (err) {
      console.error('Erreur lors de la connexion:', err);
      setError(err.response.data.message || 'Erreur lors de la connexion');
    }
  };

  return (
    <div className="login-container">
      <h2>Connexion</h2>
      <form onSubmit={handleSubmit}>
        <div className="label">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Nom d'utilisateur"
            required
          />
        </div>
        <div className="label">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
            required
          />
        </div>
        <button type="submit">Se connecter</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
