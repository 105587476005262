import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../../style/admin/dofus/elevage/MountCard.scss';

const defaultMount = {
  name: '',
  level: 0,
  sex: 'male',
  capacity: '',
  energy: 0,
  fatigue: 0,
  purity: false,
  reproduction: 0,
  characteristics: {
    love: 0,
    maturity: 0,
    endurance: 0,
   
  },
  image: '',
};

const MountCard = ({ mount = defaultMount, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedMount, setEditedMount] = useState({ ...mount });

  const capacityOptions = [
    "Précoce",
    "Amoureuse",
    "Édurante",
    "Sage",
    "Reproductrice",
    "Porteuse",
    "Infatiguable",
    "Prédisposées Génétique",
    "Caméléon",
    "Aucune"
  ];

  const handleEditChange = (e) => {
    const { name, value } = e.target;
  
    if (name in editedMount) {
      const newValue = name === 'purity' ? e.target.checked : value;
      setEditedMount(prev => ({
        ...prev,
        [name]: newValue,
      }));
    } else if (name.startsWith('characteristics.')) {
      const characteristic = name.split('.')[1];
      setEditedMount(prev => {
        const updatedCharacteristics = {
          ...prev.characteristics,
          [characteristic]: characteristic === 'serenity' ? value : Number(value),
        };
        return {
          ...prev,
          characteristics: updatedCharacteristics,
          fecundity: (updatedCharacteristics.love === 10000 &&
                      updatedCharacteristics.maturity === 10000 &&
                      updatedCharacteristics.endurance === 10000) ? 'oui' : 'non',
        };
      });
    }
  };
  

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (typeof onUpdate === 'function') {
      onUpdate(editedMount);
    }
    setIsEditing(false);
  };


  const alerts = [];
  if (mount.energy <= 0) alerts.push('Donnes lui à manger');
  if (mount.fatigue >= 240) alerts.push('Elle doit se reposer');
  
  const getEnduranceStyle = () => {
    if (mount.characteristics.serenity < -1999 && mount.characteristics.endurance !== 10000) {
      return { color: 'red' };
    }
    return { color: '#6E7DAB' };
  };
  
  const getMaturityStyle = () => {
    if (mount.characteristics.serenity >= -1999 && mount.characteristics.serenity <= 1999 && mount.characteristics.maturity !== 10000) {
      return { color: 'red' };
    }
    return { color: '#6E7DAB' };
  };
  
  const getLoveStyle = () => {
    if (mount.characteristics.serenity > 1999 && mount.characteristics.love !== 10000) {
      return { color: 'red' };
    }
    return { color: '#6E7DAB' };
  };

  
console.log(mount.image);
  return (
    <div className="mount-card">
      <h3>{mount.name}</h3>
      <img src={`${mount.image}`} alt="Monture" className='mount-image' />
      <p>Niveau: {mount.level}</p>
      <p>Sexe: {mount.sex === 'male' ? 'Mâle' : 'Femelle'}</p>
      <p>Capacité: {mount.capacity}</p>
      <p>Fécondité: {editedMount.fecundity}</p>
      <p>Énergie: {mount.energy}</p>
      <p>Fatigue: {mount.fatigue}</p>
      <p>Pureté: {mount.purity ? 'Oui' : 'Non'}</p>
      <p>Reproduction: {editedMount.reproduction}</p>

      <div className="characteristics">
        <p style={getLoveStyle()}>Amour: {mount.characteristics.love}</p>
        <p style={getMaturityStyle()}>Maturité: {mount.characteristics.maturity}</p>
        <p style={getEnduranceStyle()}>Endurance: {mount.characteristics.endurance}</p>
        <p>Sérénité: {mount.characteristics.serenity}</p>
      </div>


      {alerts.length > 0 && (
        <div className="alerts">
          {alerts.map((alert, index) => (
            <div key={index} className="alert">
              <p>{alert}</p>
            </div>
          ))}
        </div>
      )}

      {isEditing ? (
        <form onSubmit={handleEditSubmit}>
          <input
            type="text"
            name="name"
            value={editedMount.name}
            onChange={handleEditChange}
            placeholder="Nom du monture"
            required
          />
          <input
            type="number"
            name="level"
            value={editedMount.level}
            onChange={handleEditChange}
            placeholder="Niveau"
            min="0"
            required
          />
          <select name="sex" value={editedMount.sex} onChange={handleEditChange} required>
            <option value="male">Mâle</option>
            <option value="female">Femelle</option>
          </select>
          <select name="capacity" value={editedMount.capacity} onChange={handleEditChange} required>
            {capacityOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
          <input
            type="number"
            name="energy"
            value={editedMount.energy}
            onChange={handleEditChange}
            placeholder="Énergie"
            min="0"
            required
          />
          <input
            type="number"
            name="fatigue"
            value={editedMount.fatigue}
            onChange={handleEditChange}
            placeholder="Fatigue"
            min="0"
            required
          />
          <label>
            Pureté :
            <select
              name="purity"
              value={editedMount.purity ? 'oui' : 'non'}
              onChange={(e) => setEditedMount(prev => ({ ...prev, purity: e.target.value === 'oui' }))}>
              <option value="oui">Oui</option>
              <option value="non">Non</option>
            </select>
          </label>
          <input
            type="number"
            name="characteristics.love"
            value={editedMount.characteristics.love}
            onChange={handleEditChange}
            placeholder="Amour"
            required
          />
          <input
            type="number"
            name="characteristics.maturity"
            value={editedMount.characteristics.maturity}
            onChange={handleEditChange}
            placeholder="Maturité"
            required
          />
          <input
            type="number"
            name="characteristics.endurance"
            value={editedMount.characteristics.endurance}
            onChange={handleEditChange}
            placeholder="Endurance"
            required
          />
          <input
            type="number"
            name="characteristics.serenity"
            value={editedMount.characteristics.serenity}
            onChange={handleEditChange}
            placeholder="Sérénité"
            required
          />
          <input
            type="number"
            name="reproduction"
            value={editedMount.reproduction}
            onChange={(e) => handleEditChange({ target: { name: 'reproduction', value: e.target.value } })}
            placeholder="Reproduction"
            min="0"
            required
          />
          <button type="submit">Sauvegarder</button>
          <button type="button" onClick={() => setIsEditing(false)}>Annuler</button>
        </form>
      ) : (
        <button onClick={() => setIsEditing(true)}>Modifier</button>
      )}
    </div>
  );
};

MountCard.propTypes = {
  mount: PropTypes.shape({
    name: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    sex: PropTypes.oneOf(['male', 'female']).isRequired,
    capacity: PropTypes.string.isRequired,
    energy: PropTypes.number.isRequired,
    fatigue: PropTypes.number.isRequired,
    purity: PropTypes.bool,
    reproduction: PropTypes.number.isRequired,
    characteristics: PropTypes.shape({
      love: PropTypes.number.isRequired,
      maturity: PropTypes.number.isRequired,
      endurance: PropTypes.number.isRequired,
      serenity: PropTypes.number.isRequired,
    }).isRequired,
    image: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default MountCard;
