import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LiveSection from './LiveSection';
import LiveLogo from './LiveLogo';
import '../../style/streams/StreamNav.scss';
// image
import DsBijy1 from '../../images/Streams/panel-59581025-image-a3461b6f-3c33-443c-8212-bb446eca3bbf.png'
import DsBijy2 from '../../images/Streams/panel-59581025-image-bd49f32b-5603-48e3-b5f1-3b084cf0f114.png'
import DsBijy3 from '../../images/Streams/panel-59581025-image-69fd7fea-45d8-4b2b-8aa4-bb6c0320abfc.png'
import DsBijy4 from '../../images/Streams/panel-59581025-image-9d5e530e-ec0d-4dce-a85d-5c38a14aae92.png'

import DsArf1 from '../../images/Streams/panel-54543863-image-c5d13453-7d92-41d6-9b81-29fddf91202a.png'
import DsArf2 from '../../images/Streams/panel-54543863-image-e4023183-481a-4dfe-806d-2a52e7b1b1bc.png'
import DsArf3 from '../../images/Streams/panel-54543863-image-f9336135-217f-4df0-80c9-3df3579472eb.png'
import DsArf4 from '../../images/Streams/panel-54543863-image-7e19e114-8c0c-49d2-9681-44cc4df64ac1.png'
export default function SteamsNav() {
  const [activeLive, setActiveLive] = useState(null);
  const [liveStatus, setLiveStatus] = useState({
    arfdev: false,
    bijy13: false
  });

  const streams = [
    { 
      id: 'arfdev', 
      name: 'Arfdev',
      description: '"LE MEILLEUR DES MOINS BON" passionné par les jeux et le stream ! L\'envie du partage et bien trop présente ! contact pro : arfdev.pro@gmail.com', 
      links: [
        { url: 'https://discord.gg/drrfzmKY6c', image: DsArf1 },
        { url: 'https://www.youtube.com/@Ludic-Adventure', image: DsArf2 },
        { url: 'https://www.eminencegraphique.fr', image: DsArf3 },
        { url: 'https://streamlabs.com/arfdev/tip', image: DsArf4 }
      ]
    },
    { 
      id: 'bijy13', 
      name: 'Bijy13', 
      description: '"Spartiates quel est votre métier ?? "Passionné par le jeu vidéal et tout ce qui tourne autour, si tu te sens l\'ame d\'un spartiate rejoins la grande famille de Sparte !',
      links: [
        { url: 'https://discord.gg/drrfzmKY6c', image: DsBijy1 },
        { url: 'https://www.instant-gaming.com/fr/?igr=Bijy', image: DsBijy2},
        { url: 'https://streamlabs.com/bijy13/tip', image: DsBijy3 },
        { url: 'https://docs.google.com/document/d/1SPOo1BKiS-kStPA8Extw4iKlmd667_619fIh_N0uN9g/edit?tab=t.0', image: DsBijy4 }
      ]
    }
  ];

  const toggleLive = (liveId) => {
    setActiveLive(activeLive === liveId ? null : liveId);
  };

  const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_TWITCH_CLIENT_SECRET;

  const getAccessToken = async () => {
    try {
      const response = await axios.post('https://id.twitch.tv/oauth2/token', null, {
        params: {
          client_id: clientId,
          client_secret: clientSecret,
          grant_type: 'client_credentials'
        }
      });
      return response.data.access_token;
    } catch (error) {
      console.error("Erreur lors de l'obtention du token Twitch :", error);
    }
  };

  const checkLiveStatus = async () => {
    const token = await getAccessToken();
    if (!token) {
      console.error("Échec de récupération du token.");
      return;
    }

    const headers = {
      'Client-ID': clientId,
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await axios.get('https://api.twitch.tv/helix/streams', {
        headers,
        params: {
          user_login: streams.map(stream => stream.id)
        }
      });

      const liveStatusUpdate = { arfdev: false, bijy13: false };
      response.data.data.forEach((stream) => {
        liveStatusUpdate[stream.user_login] = true;
      });

      setLiveStatus(liveStatusUpdate);
    } catch (error) {
      console.error("Erreur lors de la vérification du statut du live :", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    checkLiveStatus();
    const intervalId = setInterval(checkLiveStatus, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="steams-nav">
      <div className='header'>
        <div className='header-text'>
          <p>
            Sur Twitch vous retrouverez la chaine <a href='' alt='ludicadventure-twitch'> Ludic-Adventure</a>.
            <br />
            Celle-ci n'est la que pour héberger nos chaines principales que vous retrouvez ci-dessous !
          </p>
        </div>
        <div className="header-logo">
          {streams.map((stream) => (
            <LiveLogo
              key={stream.id}
              liveId={stream.id}
              isActive={activeLive === stream.id}
              isLive={liveStatus[stream.id]}
              onClick={() => toggleLive(stream.id)}
            />
          ))}
        </div>
      </div>
      <div className="streams-container">
        {/* Affichage des sections de description et du chat Twitch */}
        <div className="live-details">
          {streams.map((stream) => (
            activeLive === stream.id && (
              <div key={stream.id} className="live-info">
                {/* Lecteur Twitch et description */}
                <LiveSection liveId={stream.id} />
                {/* Description propre à chaque live */}
                <div className="description">
                  <p>{stream.description}</p>
                  {/* Ajout des liens cliquables sous forme d'image */}
                  <div className="link-images">
                    {stream.links.map((link, index) => (
                      <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                        <img src={link.image} alt={`Lien ${index + 1}`} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
}
