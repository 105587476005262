import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../../style/admin/AdminBanqueKamas.scss';
import Kamas from '../../images/Admin/24920.png';

export default function AdminBanqueKamas() {
  const [total, setTotal] = useState(0);
  const [ventesTotal, setVentesTotal] = useState(0);
  const [depensesTotal, setDepensesTotal] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [formData, setFormData] = useState({
    type: 'vente',
    montant: '',
    commentaire: ''
  });

  useEffect(() => {
    async function fetchTransactions() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/transactions`);
        setTransactions(response.data);
        calculerTotal(response.data);
      } catch (error) {
        console.error('Erreur lors du chargement des transactions :', error);
      }
    }
    fetchTransactions();
  }, []);

  function calculerTotal(transactions) {
    let totalVentes = 0;
    let totalDepenses = 0;

    transactions.forEach((transac) => {
      if (transac.type === 'vente') {
        totalVentes += transac.montant;
      } else if (transac.type === 'dépense') {
        totalDepenses += transac.montant;
      }
    });

    setVentesTotal(totalVentes);
    setDepensesTotal(totalDepenses);
    setTotal(totalVentes - totalDepenses);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.montant <= 0) {
      alert("Le montant doit être positif.");
      return;
    }

    const newTransaction = {
      type: formData.type,
      montant: parseFloat(formData.montant),
      commentaire: formData.commentaire,
      date: new Date()
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/transactions`, newTransaction);
      setTransactions((prev) => [...prev, response.data]);
      calculerTotal([...transactions, response.data]);
      setFormData({ type: 'vente', montant: '', commentaire: '' });
    } catch (error) {
      console.error("Erreur lors de l'ajout de la transaction :", error);
    }
  };

  const goalAmount = 1000000000; // 1 milliard de kamas
  const progressPercentage = Math.min((total / goalAmount) * 100, 100); // Calcul du pourcentage de progression

  const ventes = transactions.filter(transac => transac.type === 'vente');
  const depenses = transactions.filter(transac => transac.type === 'dépense');

  return (
    <div className="admin-banque-kamas">
      <h2>Bank à Kamas</h2>
      <h2>Total des Kamas : <span className="total-kamas">{total}</span> <img src={Kamas} alt="Kamas" className="total-image" /></h2>

      <div className="columns">
        <div className="column">
          <h3>Ventes</h3>
          <p>{ventesTotal}</p>
        </div>
        <div className="column">
          <h3>Dépenses</h3>
          <p>{depensesTotal}</p>
        </div>
      </div>

      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${progressPercentage}%` }}
        ></div>
        <p>{progressPercentage}% vers votre objectif de 1 milliard de kamas!</p>
      </div>

      <form onSubmit={handleSubmit} className='formbanque'>
        <div>
          <label>Type :</label>
          <select name="type" value={formData.type} onChange={handleChange}>
            <option value="vente">Vente</option>
            <option value="dépense">Dépense</option>
          </select>
        </div>
        <div>
          <label>Montant :</label>
          <input
            type="number"
            name="montant"
            value={formData.montant}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Commentaire :</label>
          <input
            type="text"
            name="commentaire"
            value={formData.commentaire}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Ajouter la transaction</button>
      </form>

      <h3>Historique des transactions</h3>

        {/* Section des ventes et des dépenses côte à côte */}
        <div className="transactions-columns">
        <div className="transactions-column">
            <h4>Ventes</h4>
            <ul className="transactions-list">
            {ventes.map((transac, index) => (
                <li key={index} className="transaction-item">
                <p><strong>Date :</strong> {new Date(transac.date).toLocaleString()}</p>
                <p><strong>Type :</strong> {transac.type}</p>
                <p><strong>Montant :</strong> {transac.montant}</p>
                <p><strong>Commentaire :</strong> {transac.commentaire}</p>
                </li>
            ))}
            </ul>
        </div>

        <div className="transactions-column">
            <h4>Dépenses</h4>
            <ul className="transactions-list">
            {depenses.map((transac, index) => (
                <li key={index} className="transaction-item">
                <p><strong>Date :</strong> {new Date(transac.date).toLocaleString()}</p>
                <p><strong>Type :</strong> {transac.type}</p>
                <p><strong>Montant :</strong> {transac.montant}</p>
                <p><strong>Commentaire :</strong> {transac.commentaire}</p>
                </li>
            ))}
            </ul>
        </div>
        </div>

    </div>
  );
}
