import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ScreenshotCarousel from '../../../composants/ScreenshotCarousel'; // Importez le composant ScreenshotCarousel
import '../../../style/Screenshots.scss';
import NavJeux from '../../../composants/Nav-jeux';
import NavContenu from '../../../composants/Nav-contenu';
import Close from '../../../images/Admin/CLOSE.svg'
export default function DofusScreen() {
  const [screenshots, setScreenshots] = useState([]);
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [activeScreenshotIndex, setActiveScreenshotIndex] = useState(0);

  useEffect(() => {
    const fetchScreenshots = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/screenshots?tag=epopee_Fraternelle`);
        const sortedScreenshots = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setScreenshots(sortedScreenshots);
      } catch (error) {
        console.error('Erreur lors de la récupération des screenshots', error);
      }
    };

    fetchScreenshots();
  }, []);

  const openCarousel = (index) => {
    setActiveScreenshotIndex(index);
    setIsCarouselOpen(true);
  };

  const closeCarousel = () => {
    setIsCarouselOpen(false);
  };

  return (
    <div className='screenshots'>
      <NavJeux />
      <NavContenu currentGame="dofusepopeefraternelle" currentRoute="EpopeeFraternelle" />
      <h1>Dofus Screenshots</h1>
      {screenshots.length > 0 ? (
        <div className='screenshot-cards'>
          {screenshots.map((screenshot, index) => (
            <div key={screenshot._id} className='screenshot-card' onClick={() => openCarousel(index)}>
              <img 
                src={`${screenshot.image}`} 
                alt={screenshot.title} 
                className='screenshot-card-image' 
              />
              <div className='screenshot-card-info'>
                <h3>{screenshot.title}</h3>
                <p>Date: {new Date(screenshot.createdAt).toLocaleDateString()}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Aucune capture d'écran trouvée.</p>
      )}
      
      {isCarouselOpen && (
  <div className='carousel-overlay'>
    <img src={Close} className='close-carousel' onClick={closeCarousel} alt="Close Carousel" />
    <ScreenshotCarousel 
      screenshots={screenshots} 
      initialIndex={activeScreenshotIndex} 
      onClose={closeCarousel} 
    />
  </div>
)}

    </div>
  );
}
