import { useEffect, useState } from 'react';
import axios from 'axios';
import MountCard from './MountCard';
import '../../../../style/admin/dofus/elevage/MountManagement.scss';

const MountManagement = () => {
  const [mounts, setMounts] = useState([]);
  const [filter, setFilter] = useState({ 
    sex: '', 
    minLevel: 0, 
    capacity: '', 
    fecundity: '' 
  });


  
    const [newMount, setNewMount] = useState({
    name: '',
    level: '',
    sex: '',
    capacity: '',
    fecundity: 'non', // Valeur par défaut à 'non'
    energy: '',
    fatigue: '',
    purity: false,
    characteristics: { love: '', maturity: '', endurance: '', serenity: '' },
    enclosure: '',
    image: null,
    reproduction: 0,
  });

  const capacities = [
    "Précoce", 
    "Amoureuse", 
    "Édurante", 
    "Sage", 
    "Reproductrice", 
    "Porteuse", 
    "Infatiguable", 
    "Prédisposées Génétique", 
    "Caméléon",
    "Aucune"
  ];

  const [enclosures, setEnclosures] = useState([]);
  const [newEnclosure, setNewEnclosure] = useState({
    name: '',
    capacity: ''
  });

  const [selectedMount, setSelectedMount] = useState('');
  const [selectedEnclosure, setSelectedEnclosure] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mountsResponse, enclosuresResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}api/mounts`),
          axios.get(`${process.env.REACT_APP_API_URL}api/enclosures`)
          
        ]);
        setMounts(mountsResponse.data);
        setEnclosures(enclosuresResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async (updatedMount) => {
    console.log('Monture mise à jour:', updatedMount);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}api/mounts/${updatedMount._id}`, updatedMount);
      setMounts(prevMounts =>
        prevMounts.map(mount =>
          mount._id === response.data._id ? response.data : mount
        )
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la monture:", error);
    }
  };

  const groupMountsByEnclosure = (mounts) => {
    return mounts.reduce((acc, mount) => {
      const enclosureId = mount.enclosure;
      if (!acc[enclosureId]) {
        acc[enclosureId] = [];
      }
      acc[enclosureId].push(mount);
      return acc;
    }, {});
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleNewMountChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewMount(prevMount => ({
      ...prevMount,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleImageChange = (event) => {
    setNewMount(prevMount => ({
      ...prevMount,
      image: event.target.files[0]
    }));
  };

  const handleAddMount = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (const key in newMount) {
      formData.append(key, newMount[key]);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/mounts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setMounts(prevMounts => [...prevMounts, response.data]);
      resetNewMount();
    } catch (error) {
      console.error("Error adding mount:", error);
    }
  };

  const resetNewMount = () => {
    setNewMount({
      name: '',
      level: '',
      sex: '',
      capacity: '',
      fecundity: 'non', // Réinitialisation à 'non'
      energy: '',
      fatigue: '',
      purity: false,
      reproduction: 0,
      characteristics: { love: '', maturity: '', endurance: '', serenity: '' },
      enclosure: '',
      image: null
    });
  };

  const handleNewEnclosureChange = (event) => {
    const { name, value } = event.target;
    setNewEnclosure(prevEnclosure => ({ ...prevEnclosure, [name]: value }));
  };

  const handleAddEnclosure = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/enclosures`, newEnclosure);
      setEnclosures(prevEnclosures => [...prevEnclosures, response.data]);
      setNewEnclosure({ name: '', capacity: '' });
    } catch (error) {
      console.error("Error adding enclosure:", error);
    }
  };

  const handleTransferMount = async () => {
    if (selectedMount && selectedEnclosure) {
      const updatedMount = { ...mounts.find(m => m._id === selectedMount), enclosure: selectedEnclosure };
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}api/mounts/${selectedMount}`, updatedMount);
        setMounts(prevMounts => prevMounts.map(m => (m._id === selectedMount ? response.data : m)));
        setSelectedMount('');
        setSelectedEnclosure('');
      } catch (error) {
        console.error("Error transferring mount:", error);
      }
    }
  };

  const filteredMounts = mounts.filter(mount =>
    (filter.sex === '' || mount.sex === filter.sex) &&
    (filter.capacity === '' || mount.capacity === filter.capacity) &&
    (filter.fecundity === '' || mount.fecundity === filter.fecundity) &&
    (filter.minLevel === 0 || mount.level >= filter.minLevel)
  );
  

  const groupedMounts = groupMountsByEnclosure(mounts);

  return (
    <div className="mount-management">
      <div className='cadre'>
      <div className='cadre-gauche'>

        <form className="new-mount-form" onSubmit={handleAddMount}>
          <h2>Ajouter une nouvelle monture</h2>
          <input type="text" name="name" placeholder="Nom" onChange={handleNewMountChange} value={newMount.name} required />
          <input type="number" name="level" placeholder="Niveau" onChange={handleNewMountChange} value={newMount.level} required />
          <select name="sex" onChange={handleNewMountChange} value={newMount.sex} required>
            <option value="">Sexe</option>
            <option value="male">Mâle</option>
            <option value="female">Femelle</option>
          </select>
          <select name="capacity" onChange={handleNewMountChange} value={newMount.capacity} required>
            <option value="">Capacité</option>
            {capacities.map((capacity) => (
              <option key={capacity} value={capacity}>{capacity}</option>
            ))}
          </select>        <input type="number" name="energy" placeholder="Énergie" onChange={handleNewMountChange} value={newMount.energy} required />
          <input type="number" name="fatigue" placeholder="Fatigue" onChange={handleNewMountChange} value={newMount.fatigue} required />
          <label>
            Fécondité:
            <select name="fecundity" onChange={handleNewMountChange} value={newMount.fecundity}>
              <option value="non">Non</option>
              <option value="oui">Oui</option>
            </select>
          </label>
          <label>
            Pureté:
            <input type="checkbox" name="purity" onChange={handleNewMountChange} checked={newMount.purity} />
          </label>
          <label>
            Reproduction:
            <button type="button" onClick={() => setNewMount(prev => ({ ...prev, reproduction: Math.max(prev.reproduction - 1, 0) }))}>-</button>
            {newMount.reproduction}
            <button type="button" onClick={() => setNewMount(prev => ({ ...prev, reproduction: Math.min(prev.reproduction + 1, 5) }))}>+</button>
          </label>

          {['love', 'maturity', 'endurance', 'serenity'].map(characteristic => (
            <input
              key={characteristic}
              type="number"
              name={characteristic}
              placeholder={characteristic.charAt(0).toUpperCase() + characteristic.slice(1)}
              onChange={(e) => setNewMount(prev => ({
                ...prev,
                characteristics: { ...prev.characteristics, [characteristic]: e.target.value }
              }))}
              value={newMount.characteristics[characteristic]}
            />
          ))}

          <select name="enclosure" onChange={handleNewMountChange} value={newMount.enclosure} required>
            <option value="">Sélectionner un enclos</option>
            {enclosures.map(enclosure => (
              <option key={enclosure._id} value={enclosure._id}>{enclosure.name}</option>
            ))}
          </select>

          <input type="file" onChange={handleImageChange} />
          <button type="submit">Ajouter Monture</button>
        </form>
        </div>
        <div className='cadre-droite'>
          <form className="new-enclosure-form" onSubmit={handleAddEnclosure}>
            <h2>Ajouter un nouvel enclos</h2>
            <input type="text" name="name" placeholder="Nom" onChange={handleNewEnclosureChange} value={newEnclosure.name} required />
            <input type="number" name="capacity" placeholder="Capacité" onChange={handleNewEnclosureChange} value={newEnclosure.capacity} required />
            <button type="submit">Ajouter Enclos</button>
          </form>
          <div className="transfer-mount">
            <h2>Transférer une monture</h2>
            <select onChange={(e) => setSelectedMount(e.target.value)} value={selectedMount}>
              <option value="">Sélectionner une monture</option>
              {mounts.map(mount => (
                <option key={mount._id} value={mount._id}>{mount.name}</option>
              ))}
            </select>
            <select onChange={(e) => setSelectedEnclosure(e.target.value)} value={selectedEnclosure}>
              <option value="">Sélectionner un enclos</option>
              {enclosures.map(enclosure => (
                <option key={enclosure._id} value={enclosure._id}>{enclosure.name}</option>
              ))}
            </select>
            <button onClick={handleTransferMount}>Transférer</button>
          </div>
          <div className="filters">
            <h2>Filtre à monture</h2>

            {/* Filter by Sex */}
            <div className="filter-group">
              <h3>Sexe</h3>
              <select name="sex" onChange={handleFilterChange} value={filter.sex}>
                <option value="">Tous</option>
                <option value="male">Mâle</option>
                <option value="female">Femelle</option>
              </select>
            </div>

            {/* Filter by Capacity */}
            <div className="filter-group">
              <h3>Capacité</h3>
              <select name="capacity" onChange={handleFilterChange} value={filter.capacity}>
                <option value="">Toutes les capacités</option>
                {capacities.map(capacity => (
                  <option key={capacity} value={capacity}>{capacity}</option>
                ))}
              </select>
            </div>

            {/* Filter by Fecundity */}
            <div className="filter-group">
              <h3>Fécondité</h3>
              <select name="fecundity" onChange={handleFilterChange} value={filter.fecundity}>
                <option value="">Tous</option>
                <option value="oui">Oui</option>
                <option value="non">Non</option>
              </select>
            </div>

            {/* Filter by Minimum Level */}
            <div className="filter-group">
              <h3>Niveau minimum</h3>
              <input
                type="number"
                name="minLevel"
                placeholder="Niveau minimum"
                onChange={handleFilterChange}
                value={filter.minLevel}
              />
            </div>
          </div>


        </div>
      </div>
      <div className="mounts-list">
        {Object.entries(groupMountsByEnclosure(filteredMounts)).map(([enclosureId, mounts]) => (
          <div key={enclosureId}>
            <h3>{enclosures.find(e => e._id === enclosureId)?.name}</h3>
            <div className="mounts">
              {mounts.map(mount => (
                <MountCard key={mount._id} mount={mount} onUpdate={handleUpdate} />
              ))}
            </div>
          </div>
        ))}
      </div>



    </div>
  );
};

export default MountManagement;
