import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import '../style/NavContenu.scss';

const gameRoutes = {
  dofusepopeefraternelle: [
    { name: 'Aventures', path: '/dofus/epopee-fraternelle' },
    { name: 'Videos', path: '/dofus/epopee-fraternelle/videos' },
    { name: 'Screenshots', path: '/dofus/epopee-fraternelle/screenshots' },
  ],
  dofusaventurea2: [
    { name: 'Aventures', path: '/dofus/aventure-a-2' },
    { name: 'Videos', path: '/dofus/aventure-a-2/videos' },
    { name: 'Screenshots', path: '/dofus/aventure-a-2/screenshots' },
  ],
  satisfactory: [
    { name: 'Aventures', path: '/satisfactory' },
    { name: 'Videos', path: '/satisfactory/videos' },
    { name: 'Screenshots', path: '/satisfactory/screenshots' },
  ],
  diabloIV: [
    { name: 'Aventures', path: '/diabloIV' },
    { name: 'Videos', path: '/diabloIV/videos' },
    { name: 'Screenshots', path: '/diabloIV/screenshots' },
  ],
  manofmedan: [
    { name: 'Aventures', path: '/dark-pictures/manofmedan' },
    { name: 'Videos', path: '/dark-pictures/manofmedan/videos' },
    { name: 'Screenshots', path: '/dark-pictures/manofmedan/screenshots' },
  ],
  worldofwarcraft: [
    { name: 'Aventures', path: '/worldofwarcraft' },
    { name: 'Videos', path: '/worldofwarcraft/videos' },
    { name: 'Screenshots', path: '/worldofwarcraft/screenshots' },
  ],
  gtav: [
    { name: 'Aventures', path: '/gtav' },
    { name: 'Videos', path: '/gtav/videos' },
    { name: 'Screenshots', path: '/gtav/screenshots' },
  ],
  bramble: [
    { name: 'Aventures', path: '/bramble' },
    { name: 'Videos', path: '/bramble/videos' },
    { name: 'Screenshots', path: '/bramble/screenshots' },
  ],
};

export default function NavSecondary({ currentGame, currentRoute }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const routes = gameRoutes[currentGame];
    if (routes) {
      const index = routes.findIndex(route => route.path === location.pathname);
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [currentGame, location.pathname]);

  const handlePrevious = () => {
    const routes = gameRoutes[currentGame];
    if (!routes) return;

    const newIndex = currentIndex === 0 ? routes.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    navigate(routes[newIndex].path);
  };

  const handleNext = () => {
    const routes = gameRoutes[currentGame];
    if (!routes) return;

    const newIndex = currentIndex === routes.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    navigate(routes[newIndex].path);
  };

  return (
    <nav className="nav-tertiary">
      <div className="Nav-contenu">
        {currentGame && gameRoutes[currentGame]?.map((game) => (
          <div key={game.name} className="nav-item-container">
            <NavLink 
              to={game.path} 
              className={({ isActive }) => `nav-item ${isActive ? 'active' : ''}`}
            >
              <span className={location.pathname === game.path ? 'selected-game' : 'unselected-game'}>
                {game.name}
              </span>
            </NavLink>
          </div>
        ))}
      </div>
    </nav>
  );
}
