// src/pages/Actualite.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Card from '../composants/Card'; // Importez votre composant Card
import '../style/Actualite.scss';

export default function Actualite() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/articles`);
        const sortedArticles = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setArticles(sortedArticles);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles', error);
      }
    };

    fetchArticles();
  }, []);

  const handleCardClick = (article) => {
    navigate(`/article/${article._id}`, { state: { article } });
  };

  return (
    <div className='actu'>
      <h1>Site en développement</h1>
      <h1>Toutes nos actus</h1>
      <ul className='all-card'>
        {articles.map((article) => (
          <Card key={article._id} article={article} onClick={() => handleCardClick(article)} />
        ))}
      </ul>
    </div>
  );
}
