// src/pages/ManOfMedan/ManOfMedanVideo.js
import React from 'react';
import VideoPlayer from '../../composants/VideoPlayer';
import NavJeux from '../../composants/Nav-jeux';
import NavContenu from '../../composants/Nav-contenu';

export default function ManOfMedanVideo() {
  const API_KEY = 'AIzaSyDmKxhtlFeM5uljkI9wZTYus9SbxqZbGqc'; // Votre clé API
  const PLAYLIST_ID = 'PLPYIAexfSG__fAf7AZQfbbByT3L8qvW-P'; // ID de la playlist Man of Medan

  return (
    <>
      <NavJeux />
      <NavContenu currentGame="gtav" />
      <VideoPlayer playlistId={PLAYLIST_ID} apiKey={API_KEY} title="Dernières vidéos de Man Of Medan" />
    </>
  );
}
