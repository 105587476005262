import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import '../style/NavJeux.scss';
import Separator from '../images/Nav/chevron.svg';

const games = [
  { name: 'Dofus', path: '/dofus' },
  { name: 'Diablo 4', path: '/diabloIV' },
  { name: 'Satisfactory', path: '/satisfactory' },
  { name: 'The Dark Pictures', path: '/dark-pictures' },
  { name: 'WorldofWarcraft', path: '/worldofwarcraft' },
  { name: 'GTAV', path: '/gtav' },
  { name: 'Bramble', path: '/bramble' },
];

export default function NavSecondary() {
  const location = useLocation(); // Permet de connaître l'URL actuelle
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const currentPath = location.pathname;
    const index = games.findIndex(game => currentPath.startsWith(game.path));
    if (index !== -1) {
      setCurrentIndex(index); // Met à jour l'index en fonction de l'URL active
    }
  }, [location.pathname]); // Met à jour chaque fois que l'URL change

  const handlePrevious = () => {
    const newIndex = currentIndex === 0 ? games.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    navigate(games[newIndex].path);
  };

  const handleNext = () => {
    const newIndex = currentIndex === games.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    navigate(games[newIndex].path);
  };

  return (
    <nav className="nav-secondary">
      <div className="Nav-games">
        <button onClick={handlePrevious} className="nav-arrow">&lt;</button>

        {games.map((game, index) => (
          <div key={game.name} className="nav-item-container">
            <NavLink 
              to={game.path} 
              className={`nav-item ${currentIndex === index ? 'active' : ''}`}
            >
              <span className={currentIndex === index ? 'selected-game' : 'unselected-game'}>
                {game.name}
              </span>
            </NavLink>

            {index < games.length - 1 && (
              <img src={Separator} alt="Separator" className="separator-icon" />
            )}
          </div>
        ))}

        <button onClick={handleNext} className="nav-arrow">&gt;</button>
      </div>
    </nav>
  );
}
