import React from 'react';
import '../../style/admin/AdminLiens.scss'; // Assurez-vous d'ajouter un fichier SCSS pour le style.

export default function AdminLiens() {
  const liens = [
    { text: 'Site officiel Dofus', url: 'https://www.dofus.com/fr' },
    { text: 'Forum Dofus', url: 'https://www.dofus.com/fr/forum' },
    { text: 'Wiki Dofus', url: 'https://dofus.fandom.com/fr/wiki/Dofus_Wiki' },
    { text: 'Boutique Dofus', url: 'https://www.dofus.com/fr/boutique' },
    { text: 'Classements Dofus', url: 'https://www.dofus.com/fr/leaderboards' },
    { text: 'Dofus Tools', url: 'https://www.dofus.com/fr/leaderboards' },
    { text: 'Dofus pour les noobs', url: 'https://www.dofuspourlesnoobs.com/' },
    { text: 'DofusPlanet', url: 'https://dofusplanet.fr/' },
    { text: 'DofusBook', url: 'https://www.dofusbook.net/fr/' },
    { text: 'DofusDataBase', url: 'https://dofusdb.fr/fr/' },
    { text: 'DofusPortal (dimmension)', url: 'https://dofus-portals.fr/' },
    { text: 'Faelis ( croisement DD ) ', url: 'https://felis-silvestris.lescigales.org/' },
    { text: 'Dofensive ( info mobs )', url: 'https://dofensive.com/fr/' },
    { text: 'Huzounet.fr', url: 'https://huzounet.fr/' },
  ];

  return (
    <div className="admin-liens">
      <h2>Liens utiles pour Dofus</h2>
      <div className="liens-list">
        {liens.map((lien, index) => (
          <a key={index} href={lien.url} className="lien-item" target="_blank" rel="noopener noreferrer">
            {lien.text}
          </a>
        ))}
      </div>
    </div>
  );
}
